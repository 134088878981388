/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {NgChatFriendsListComponent as ɵg} from './ng-chat/components/ng-chat-friends-list/ng-chat-friends-list.component';
export {NgChatOptionsComponent as ɵf} from './ng-chat/components/ng-chat-options/ng-chat-options.component';
export {NgChatWindowComponent as ɵh} from './ng-chat/components/ng-chat-window/ng-chat-window.component';
export {NgChat as ɵa} from './ng-chat/ng-chat.component';
export {EmojifyPipe as ɵb} from './ng-chat/pipes/emojify.pipe';
export {GroupMessageDisplayNamePipe as ɵe} from './ng-chat/pipes/group-message-display-name.pipe';
export {LinkfyPipe as ɵc} from './ng-chat/pipes/linkfy.pipe';
export {SanitizePipe as ɵd} from './ng-chat/pipes/sanitize.pipe';